<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Users'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'User Detail'"
      :title="'User Detail'"
    >
      <div class="form-create__buttons">
        <MainButton @click.native="handleToEdit">Edit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer v-if="user != null">
      <div class="detail__row">
        <div class="detail__label">Name:</div>
        <div class="detail__value">{{ user.name }}</div>
      </div>
      <div class="detail__row">
        <div class="detail__label">Email:</div>
        <div class="detail__value">{{ user.email }}</div>
      </div>
      <div class="detail__row">
        <div class="detail__label">Group:</div>
        <div class="detail__value">{{ user.groups[0].name }}</div>
      </div>
      <div class="detail__row">
        <div class="detail__label">Subscription:</div>
        <div v-if="userActiveSubscription.length == 0" class="detail__value">None</div>
        <div v-else class="detail__value">Yes</div>
      </div>
      <div v-if="userActiveSubscription.length == 0" class="detail__button">
        <MainButton @click.native="handleOpenModal">Assign Subscription</MainButton>
      </div>
      <div class="detail__button">
        <MainButton @click.native="handleOpenDownloads">Check Downloads User</MainButton>
      </div>
      <div v-if="showDownloads" class="detail__downloads">
        <div v-if="downloads.length">
          <CustomTable
            v-if="downloads.length"
            :data="downloads"
            :deleted-keys="tableOptionsDownload.deletedKeys"
            :rename-columns="tableOptionsDownload.renameColumn"
            :custom-order="tableOptionsDownload.customOrder"
            :is-action-block-visible="tableOptionsDownload.isActionBlockVisible"
          >
          </CustomTable>
          <div class="detail__pagination">
            <div
              v-if="page_downloads != 1 && this.dataDownloads.lastPage > 1"
              class="detail__button"
            >
              <MainButton @click.native="handlePrevPage">Prev Page</MainButton>
            </div>
            <div v-if="page_downloads < this.dataDownloads.lastPage" class="detail__button">
              <MainButton @click.native="handleNextPage">Next Page</MainButton>
            </div>
          </div>
        </div>
      </div>
      <div v-if="userActiveSubscription.length > 0">
        <div class="detail__title">Subscription Active</div>
        <div class="detail__row">
          <div class="detail__label">Date Start:</div>
          <div class="detail__value">
            {{ userActiveSubscription[0].activeFrom }}
          </div>
        </div>
        <div class="detail__row">
          <div class="detail__label">Date End:</div>
          <div class="detail__value">
            {{ userActiveSubscription[0].activeTo }}
          </div>
        </div>
        <div class="detail__row">
          <div class="detail__label">Name:</div>
          <div class="detail__value">
            {{ userActiveSubscription[0].subscription.name }}
          </div>
        </div>
        <div class="detail__row">
          <div class="detail__label">Price:</div>
          <div class="detail__value">
            {{ userActiveSubscription[0].subscription.price }}
          </div>
        </div>
        <div class="detail__button">
          <MainButton @click.native="handleOpenModalDelete">De-assign Subscription</MainButton>
        </div>
      </div>
      <div class="detail__history">
        <div v-if="userSubscription.length">
          <CustomTable
            v-if="userSubscription.length"
            :data="userSubscription"
            :deleted-keys="tableOptionsHistory.deletedKeys"
            :rename-columns="tableOptionsHistory.renameColumn"
            :custom-order="tableOptionsHistory.customOrder"
            :is-action-block-visible="tableOptionsHistory.isActionBlockVisible"
          >
          </CustomTable>
        </div>
      </div>
    </ContentContainer>
    <MainModal v-if="deAssignSubscription" :on-close="handleCloseModalDelete" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">Do you want de-assign subscription?</div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseModalDelete">NO</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeAssignSubscription" :button-cancel="true"
              >YES</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="chooseSubscription" :on-close="handleCloseModal" :modal-center="true">
      <div class="list__confirm">
        <div v-if="subscriptionList.length">
          <CustomTable
            v-if="subscriptionList.length"
            :data="subscriptionList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :handleButton="handleAssignSubscription"
            :order="orderSort"
          >
          </CustomTable>
        </div>
        <div class="list__no-have" v-else>No subscription found by this parameter.</div>
        <p class="form-create__label" style="padding-top: 20px">Assign at</p>
        <div class="input-container">
          <date-picker
            v-model="dateSubscription"
            type="date"
            :value-type="'YYYY-MM-DD HH:mm:ss'"
            :show-second="false"
            :format="'DD.MM.YYYY'"
            :title-format="'DD.MM.YYYY'"
            placeholder="dd.mm.yyy"
          ></date-picker>
          <!-- <Notification :error-messages="validationErrors['time']" /> -->
        </div>
      </div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import usersApi from "~/api/user";
import subscriptionApi from "~/api/subscription";
import DatePicker from "vue2-datepicker";
import moment from "moment";

import "vue2-datepicker/index.css";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";

export default {
  name: "UserDetail",
  metaInfo: {
    title: "User Detail",
  },
  data() {
    return {
      user: null,
      userActiveSubscription: [],
      userSubscription: [],
      directionSort: true,
      orderSort: "id",
      chooseSubscription: false,
      dateSubscription: null,
      deAssignSubscription: false,
      showDownloads: false,
      page_downloads: 1,
      downloads: [],
      dataDownloads: null,
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "price",
            to: "Price",
          },
          {
            from: "term",
            to: "Term",
          },
        ],
        customOrder: ["id", "name", "price", "term", "null"],
      },
      tableOptionsDownload: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "templateId",
            to: "ID",
          },
          {
            from: "downloadedAt",
            to: "Downloaded at",
          },
          {
            from: "templateName",
            to: "Template Name",
          },
          {
            from: "templateUrl",
            to: "Template Url",
          },
        ],
        customOrder: ["templateId", "downloadedAt", "templateName", "templateUrl", "null"],
      },
      tableOptionsHistory: {
        deletedKeys: ["null"],
        isActionBlockVisible: false,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "price",
            to: "Price",
          },
          {
            from: "term",
            to: "Term",
          },
          {
            from: "date_start",
            to: "Date Start",
          },
          {
            from: "date_end",
            to: "Date End",
          },
          // {
          //   from: "paid",
          //   to: "Paid",
          // },
          {
            from: "active",
            to: "Active",
          },
        ],
        customOrder: ["id", "name", "price", "term", "date_start", "date_end", "active"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    MainModal,
    CustomTable,
    DatePicker,
  },
  mounted() {
    this.getItem();
    this.getUserActiveSubscription();
    this.getList();
    this.getUserSubscription();
  },
  computed: {
    ...mapState("users", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("subscription", ["error", "loadingProcess", "subscription", "subscriptionList"]),
    getFirstLink() {
      return ROUTE.USERS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleOpenModalDelete() {
      this.deAssignSubscription = true;
    },
    handleCloseModalDelete() {
      this.deAssignSubscription = false;
    },
    handlePrevPage() {
      this.page_downloads = +this.page_downloads - 1;
      this.handleOpenDownloads();
    },
    handleNextPage() {
      this.page_downloads = +this.page_downloads + 1;
      this.handleOpenDownloads();
    },
    handleOpenDownloads() {
      let url = `?page=${this.page_downloads || 1}&user_id=${this.user.id}`;
      try {
        usersApi.getUserDownloads(url).then((res) => {
          const data = res.data;
          if (res.success) {
            this.downloads = data.downloads.items;
            this.dataDownloads = data.downloads;
            this.showDownloads = true;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleDeAssignSubscription() {
      const data = {
        user_id: this.getId,
        subscription_id: this.userActiveSubscription[0].subscription.id,
      };
      try {
        subscriptionApi.deAssignSubscription(data).then((res) => {
          const data = res.data;
          if (res.success) {
            this.getItem();
            this.getUserActiveSubscription();
            this.getUserSubscription();
            this.deAssignSubscription = false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.USERS_LIST.replace(":page", 1),
      });
    },

    handleCloseModal() {
      this.chooseSubscription = false;
    },

    handleOpenModal() {
      this.chooseSubscription = true;
    },

    handleAssignSubscription(row) {
      const data = {
        user_id: this.getId,
        subscription_id: row.id,
        active_from: this.dateSubscription,
        active: 1,
        paid: 1,
      };

      try {
        subscriptionApi.assignSubscription(data).then((res) => {
          const data = res.data;
          if (res.success) {
            this.getItem();
            this.getUserActiveSubscription();
            this.getUserSubscription();
            this.chooseSubscription = false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("subscription/getSubscriptionList", url);
    },

    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },

    handleToEdit() {
      this.$router.push(`/user/edit/${this.getId}`);
    },

    getItem() {
      const url = `/${this.getId}`;
      try {
        usersApi.getItemUser(url).then((res) => {
          const data = res.data;
          this.user = data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getUserActiveSubscription() {
      const url = `/${this.getId}`;
      try {
        subscriptionApi.getUserActiveSubscription(url).then((res) => {
          const data = res.data;
          this.userActiveSubscription = data.items;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getUserSubscription() {
      const url = `/${this.getId}`;
      try {
        subscriptionApi.getUserSubscription(url).then((res) => {
          const data = res.data;
          const userSubscription = [];
          if (data.items.length > 0) {
            for (let i = 0; data.items.length > i; i++) {
              const item = {
                id: data.items[i].subscription.id,
                name: data.items[i].subscription.name,
                term: data.items[i].subscription.term,
                date_start: data.items[i].activeFrom,
                date_end: data.items[i].activeTo,
                price: data.items[i].subscription.price,
                active: data.items[i].active,
                // paid: data.items[i].paid == 1 ? "Paid" : "No paid",
              };

              userSubscription.push(item);
            }
          }
          this.userSubscription = userSubscription;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  &__pagination {
    padding: 20px 0;
    display: flex;

    .detail__button {
      margin-right: 10px;
    }
  }
  &__row {
    display: flex;
    padding: 10px 0;
    align-items: center;
  }

  &__label {
    width: 120px;
    font-size: 18px;
    font-weight: 700;
    margin-right: 30px;
  }

  &__title {
    font-size: 20px;
    padding: 20px 0;
    font-weight: 700;
  }

  &__button {
    width: 250px;
    margin: 10px 0;
  }
}

/deep/ .main-modal.center .modal-container {
  max-width: 800px;
}
/deep/ .mx-datepicker {
  width: 100%;
}
/deep/ .mx-datepicker svg {
  position: static !important;
}

/deep/.main-modal {
  z-index: 2000;
}

/deep/.tools-panel {
  z-index: 2000;
}
</style>
